/* ++++++++++++++++++++++++++++++++++++++++++++
 *
 * コンテンツ内で使い回しそうな JS 拡張系
 *
 * +++++++++++++++++++++++++++++++++++++++++ */

(function($) {

  /* ++++++++++++++++++++++++++++++++++++++++++++
  //
  // jQuery 機能追加
  //
  ++++++++++++++++++++++++++++++++++++++++++++ */
  jQuery.fn.extend({
    /* ボタンによるブロックの表示のオン・オフ
    // 例） $('.aca').accordionArea();
    // aca (aca-on||aca-off)
    //  ├ aaca-btn
    //  ├ aca-body - aca-body-inner
    --------------------------------------------*/
    accordionArea: function(mode) {
      mode = mode || "display";
      var animeTime = 300;

      $.each(this, function() {
        var blk = $(this);
        var openCloseBtn = $(this)
          .find(".aca-btn")
          .first();
        var openCloseSwitch = $(this).find(".aca-btn, .aca-off_txt");
        var accordionArea = $(this)
          .find(".aca-body")
          .first();
        var accordionAreaInner = $(this).find(".aca-body .aca-body-inner");
        var maxHeight = 0;

        if (!blk.hasClass("aca-off") && !blk.hasClass("aca-on"))
          blk.addClass("aca-off");

        openCloseSwitch.on("click keydown", function() {
          var targetPos = openCloseBtn.offset().top;
          if (mode == "display") {
            accordionArea.slideToggle(animeTime, function() {
              blk.toggleClass("aca-on");
              blk.toggleClass("aca-off");
              openCloseSwitch.blur();
            });
          } else if (mode == "visibility") {
            maxHeight = 0;

            if (blk.hasClass("aca-off")) {
              maxHeight =
                parseInt(accordionAreaInner.outerHeight()) +
                parseInt(accordionAreaInner.css("margin-top")) +
                100;
            }

            accordionArea.css("max-height", maxHeight + "px");

            blk.toggleClass("aca-on");
            blk.toggleClass("aca-off");
          }

          scrollAnchor(targetPos, true);
        });
      });
    },
    /* 汎用 タブボックス
    // 例） $('.tabboxset_t1').tabBox();
    // data-roleのコンテンツを表示
    // tabBox
    //  ├ .tbbst-nav - .tbbst-n-item(_01) [data-tab-content="tbbst-content_01"] ← コンテンツのクラス
    //  ├ .bbst-contents - .tbbst-c-item(_01)
    --------------------------------------------*/
    tabBox: function() {
      var tabBox = this;
      var tabNavItems = tabBox.find(".tbbst-nav .tbbst-n-item");
      var tabContentsItems = tabBox.find(".tbbst-contents .tbbst-c-item");
      var tgtContent = null;
      var thisItem = null;
      tabNavItems.on("click", function() {
        thisItem = $(this);
        // ターゲット取得
        tgtContentItem = tabBox.find(
          ".tbbst-contents ." + thisItem.data("tab-content")
        );
        // selected 削除
        tabNavItems.removeClass("selected");
        tabContentsItems.removeClass("selected").css({ display: "none" });
        // selected 付与
        thisItem.addClass("selected");
        tgtContentItem.css({ display: "block" }).addClass("selected");
      });
    }
  });
  /* ++++++++++++++++++++++++++++++++++++++++++++
  //
  // ターゲットのポジションへスロール
  //　引数（tp:targetPos 数字, h:header 考慮するかどうか）
  //
  ++++++++++++++++++++++++++++++++++++++++++++ */
  function scrollAnchor(tp, h) {
    var h = h || false;
    var hH = 20;
    if(h && !gns.pinchZoom) hH = gns.header.innerHeight();
    gns.htmlBody.velocity('scroll', { duration: 500, offset: (tp - hH), easing: "easeOutQuart" });
  }

})(jQuery);